<template>
  <v-card flat>
    <v-container>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              autofocus
              v-model="proveedor.nombre"
              label="Nombre"
              v-validate="'required|max:25'"
              :counter="30"
              :error-messages="errors.collect('nombre')"
              data-vv-name="nombre"
              required
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              outlined
              v-model="proveedor.direccion"
              label="Dirección"
              v-validate="'max:50'"
              :counter="50"
              :error-messages="errors.collect('direccion')"
              data-vv-name="direccion"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              v-model="proveedor.telefono"
              label="Telefono"
              v-validate="'numeric|min:7|max:30'"
              :error-messages="errors.collect('telefono')"
              data-vv-name="telefono"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              v-model="proveedor.email"
              label="Email"
              v-validate="'email'"
              :error-messages="errors.collect('email')"
              data-vv-name="email"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <TipoDocumentoVSelect :entity="proveedor">  </TipoDocumentoVSelect>

          </v-col>
           <v-col>

            <v-text-field
              outlined
              v-model="proveedor.documento"
              label="Documento"
              v-validate="'required|numeric|min:7|max:11'"
              :error-messages="errors.collect('documento')"
              data-vv-name="documento"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7">
            <v-row>
              <v-col>
                <v-autocomplete
                  outlined
                  :items="provincias"
                  append-icon
                  v-model="id_provincia"
                  hide-no-data
                  clearable
                  item-text="nombre"
                  item-value="id"
                  v-validate="'required'"
                  label="Provincia"
                  :error-messages="errors.collect('provincia')"
                  data-vv-name="provincia"
                  @change="refrescarLocalidades"
                ></v-autocomplete>
              </v-col>

              <v-col>
                <v-autocomplete
                  autocomplete="off"
                  v-model="proveedor.localidad.id"
                  :items="localidades"
                  outlined
                  :search-input.sync="searchInputLocalidad"
                  append-icon
                  no-data-text="Sin localidades"
                  clearable
                  item-text="nombre"
                  item-value="id"
                  v-validate="'required'"
                  label="Localidad"
                  :error-messages="errors.collect('localidad')"
                  data-vv-name="localidad"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <div class="title">Contable</div>
              </v-col>
              <v-col cols="12">
                <CondicionIvaVSelect :entity="proveedor"></CondicionIvaVSelect>
              </v-col>
              <v-col cols="12">
                <RubroCompraListSelect
                  :value="proveedor.rubro_compra"
                  @process-select="setRubroCompra"
                  title="Rubro compra"
                ></RubroCompraListSelect>
              </v-col>
              <v-col cols="12">
                Cuenta Corriente:
                <CuentasTreeviewDialog
                  :planDeCuentas="planDeCuentas"
                  :initialCuenta="proveedor.cuenta_corriente"
                  @update:initialCuenta="setCuentaCorriente"
                ></CuentasTreeviewDialog>
              </v-col>
              <v-col cols="12">
                Cuenta Contado:
                <CuentasTreeviewDialog
                  :planDeCuentas="planDeCuentas"
                  :initialCuenta="proveedor.cuenta_contado"
                  @update:initialCuenta="setCuentaContado"
                ></CuentasTreeviewDialog>
              </v-col>
              <v-col cols="12">
                Cuenta Otros
                <CuentasTreeviewDialog
                  :planDeCuentas="planDeCuentas"
                  :initialCuenta="proveedor.cuenta_otros"
                  @update:initialCuenta="setCuentaOtros"
                ></CuentasTreeviewDialog>
              </v-col>
              <v-col cols="12"> </v-col>
            </v-row>
          </v-col>
          <v-col cols="5">
            <producto-filtro-categoria
              :onlyParents="true"
              @select-categoria="setCategoriasProveedor"
              :categoriasSeleccionadas="proveedor.categorias_id"
              type="list"
            ></producto-filtro-categoria>
          </v-col>
          <v-row> </v-row>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" class="mr-2">
          <v-btn text color="grey" @click="$emit('close-dialog')"
            >Cancelar</v-btn
          >

          <v-btn
            color="green"
            :loading="loading"
            @click="validateForm()"
            :disabled="errors.any()"
            >{{ textButton }}</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import VSelectWithValidation from "@/components/inputs/VSelectWithValidation";
import CategoriaSelectMultiple from "@/components/categorias/consultar/categoria.select.multiple.vue";
import ProductoFiltroCategoria from "@/components/producto/filtros/producto.filtro.categoria.vue";

import CondicionIvaVSelect from "@/components/util/CondicionIvaVSelect.vue";
import CuentasTreeviewDialog from "@/components/contabilidad/CuentasTreeviewDialog.vue";
import RubroCompraListSelect from "@/components/contabilidad/RubroCompraListSelect.vue";
import TipoDocumentoVSelect from "@/components/util/TipoDocumentoVSelect.vue";


export default {
  name: "FormProveedor",
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VSelectWithValidation,
    CategoriaSelectMultiple,
    ProductoFiltroCategoria,
    CondicionIvaVSelect,
    CuentasTreeviewDialog,
    RubroCompraListSelect,
    TipoDocumentoVSelect
  },
  $_veeValidate: {
    validator: "new",
  },
  data() {
    return {
      localidades: [],
      localidadesProveedor: [],
      id_provincia: null,
      loading: false,
      searchInput: "",
      searchInputLocalidad: "",
      categoriaSelected: null,
    };
  },

  props: {
    proveedor: {
      type: Object,
      required: true,
    },
    provincias: {
      type: Array,
      required: true,
    },
    textButton: {
      type: String,
      required: false,
      default: "Registrar",
    },

    isUpdate: {
      type: Boolean,
      required: true,
    },
    planDeCuentas: {
      type: Array,
      required: true,
    },
  },

  methods: {
    setCategoria(categoria) {
      this.categoriaSelected = categoria;
    },
    setCuentaCorriente(value) {
      console.log(value);
      this.proveedor.cuenta_corriente = value[0];
    },
    setCuentaContado(value) {
      this.proveedor.cuenta_contado = value[0];
    },
    setCuentaOtros(value) {
      this.proveedor.cuenta_otros = value[0];
    },
    setRubroCompra(value) {
      console.log(value);
      
      this.proveedor.rubro_compra = value;
    },

    setCategoriasProveedor(categorias) {
      console.log(categorias);
      this.proveedor.categorias = categorias;
    },
    refrescarLocalidades(id_prov) {
      this.fetchLocalidades(id_prov);
      this.proveedor.localidad.id = null;
    },
    async fetchLocalidades(id_prov) {
      try {
        const localidades = await this.axios.get(
          `/soporte/provincias/${id_prov}/localidades`
        );

        this.localidades = localidades.data;
        this.searchInputLocalidad = "";
      } catch (error) {}
    },

    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          console.log(this.proveedor.categorias);
          this.$emit("processProveedor", this.proveedor);
        } else {
          this.$validator.validate();
        }
      });
    },
  },

  mounted() {
    if (this.isUpdate) {
      this.id_provincia = this.proveedor.localidad.provincia.id;
      let id = this.proveedor.localidad.provincia.id;
      this.fetchLocalidades(id);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
