<template>
  <div>
    <v-list shaped v-if="categorias && categorias.parent && categorias.parent.children.length > 0">
      <v-list-item-group
        v-model="categoriasSeleccionadasComputed"
        multiple
        color="primary"
      >
        <template v-for="(item, i) in categorias.parent.children ">
          <v-divider
            v-if="!item"
            :key="`divider-${i}`"
          ></v-divider>

          <v-list-item
            v-else
            :key="`item-${i}`"
            :value="item.id"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'categoras.arbol.list.parents',
  props: {
    categorias: {
      type: Object,
      required: true,
    },
    categoriasSeleccionadas: {
      type: Array,
      required: false,
      default: () => []
    }
  },
 
  computed: {
    categoriasSeleccionadasComputed: {
      get: function () {
        return this.categoriasSeleccionadas
      },
      set: function (categoria) {
        this.$emit("select-categoria", categoria)
      }
    }
  },
  methods: {
    verProductosCategoria(item) {
      this.$emit('select-categoria', item)
    }
  },
}
</script>

<style lang="scss" scoped>

</style>