<template>
  <div
    v-if="
      categorias && categorias.parent && categorias.parent.children.length > 0
    "
  >
    <span class="text-body-2"><b>Categorias</b></span>

    <v-checkbox
      class="pb-0 pt-0"
      dense
      multiple
      v-for="(item, i) in categorias.parent.children"
      :key="i"
      :label="`${item.name}`"
      v-model="selected"
      :value="item"
      @change="select(item, $event)"
    >
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: "categoras.select.checkbox",

  props: {
    categorias: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selected: [],
    };
  },

  methods: {
    select: function (item) {
      this.$emit("select-categoria", item);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>