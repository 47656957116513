<template>
  <li :style="item.color ? `color: ${item.color}` : ''">
    <v-card
      flat
      @click="toggle(item)"
      @dblclick="doubleClick(item)"
      :color="
        categoriaSelected
          ? categoriaSelected.name == item.name
            ? ''
            : ''
          : ''
      "
    >
      <v-row>
        <v-container>
          <span
            class="subtitle-2"
            :style="
              categoriaSelected
                ? categoriaSelected.name == item.name
                  ? 'color: #B1D4E0'
                  : ''
                : ''
            "
            >{{ item.name }}</span
          >
          <v-icon color="blue" v-if="isFolder">{{
            isOpen ? "mdi-menu-up" : "mdi-menu-down"
          }}</v-icon>
        </v-container>
      </v-row>
    </v-card>
    <v-divider></v-divider>
    <v-container>
      <ol class="mt-2" v-show="isOpen" v-if="isFolder">
        <tree-item-categoria
          class="item"
          v-for="(child, index) in item.children"
          :key="index"
          :item="child"
          @make-folder="$emit('make-folder', $event)"
        ></tree-item-categoria>
      </ol>
    </v-container>
  </li>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "tree-item-categoria",
  props: {
    item: Object,
  },
  data: function () {
    return {
      isOpen: true,
      select: null,
    };
  },
  computed: {
    isFolder: function () {
      return this.item.tiene_hijos;
    },
    isLeaf() {
      return this.item.children && !this.item.children.length;
    },
    ...mapState("categorias", ["categoriaSelected"]),
  },
  methods: {
    toggle: function (item) {
      if (item.children && item.children.length > 0) {
        this.isOpen = !this.isOpen;
      } else {
        this.$emit("make-folder", item);
        this.isOpen = true;
      }
    },
    doubleClick: function (item) {
      if (!item.children && !item.children.length) {
        // this.$emit("make-folder", item);
        // this.isOpen = true;
      }
    },
  },
};
</script>

<style scoped></style>
