<template>
   <v-list
    class="pb-0 pt-0"
    dense
    nav
    v-if="categorias && categorias.parent && categorias.parent.children.length > 0"
  >
    <v-list-item
      class="mt-0 mb-0"
      @click="verProductosCategoria(item)"
      v-for="(item, i) in categorias.parent.children"
      :key="i"
    >
      <v-list-item-content>
        <v-list-item-title v-text="item.name"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
    export default {
        name: 'categoras.arbol.list',
        props: {
            categorias: {
                type: Object,
                required: true,
            }
        },
        methods: {
          verProductosCategoria(item){
            this.$emit('select-categoria',item)
          }
        },
    }
</script>

<style lang="scss" scoped>

</style>