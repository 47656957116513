<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <span>

        <v-btn outlined v-bind="attrs" v-on="on" text>
          {{ initialCuenta ? initialCuenta.codigo : "Seleccionar cuenta" }}
        </v-btn>
        <span> {{ initialCuenta ? initialCuenta.nombre : "" }}</span>
      </span>

    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Seleccionar cuenta</span>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" icon>
          <v-icon color="grey">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <CuentasTreeview
          :planDeCuentas="planDeCuentas"
          :initialCuenta="[initialCuenta]"
          @update:initialCuenta="updateInitialCuenta"
        ></CuentasTreeview>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CuentasTreeview from "@/components/contabilidad/CuentasTreeview.vue";

export default {
  components: {
    CuentasTreeview,
  },
  props: {
    planDeCuentas: {
      type: Array,
      required: true,
    },
    initialCuenta: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    updateInitialCuenta(cuenta) {
      this.dialog = false;
      this.$emit("update:initialCuenta", cuenta);
    },
  },
};
</script>