<template>
  <li v-if="categorias">
    <v-list-item  v-if="categorias.mptt_level > 0" dense flat @dblclick="doubleClick(categorias)">
        <!-- <span v-if="isFolder" class="subtitle-2">{{ categorias.name }}</span> -->
        <v-checkbox
         v-if="categorias.mptt_level > 1 || !isFolder"
          v-model="selectedCategory"
          :label="categorias.name == 'CATEGORIA'? 'Todas las categorías' : `${categorias.name}`"
          hide-details
          :value="categorias"
          class="shrink mr-2 mt-0"
          @click="changeCategory(categorias)"
        ></v-checkbox>
        <span v-else> {{categorias.name}}</span>
        <v-icon color="blue" @click="toggle(categorias)" v-if="isFolder && categorias.name != 'CATEGORIA'">{{
          isOpenFolder ? "mdi-menu-up" : "mdi-menu-down"
        }}</v-icon>
    </v-list-item>
      <ol class="mt-2" v-show="isOpenFolder" v-if="isFolder">
        <categorias-arbol-list-tree
          @select-categoria="changeCategory"
          class="item"
          v-for="child in categorias.children"
          :key="child.id"
          :categorias="child"
        ></categorias-arbol-list-tree>
      </ol>
  </li>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "categorias-arbol-list-tree",

  props: {
    categorias: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      isOpen: false,
      select: null,
      selected: false,
      
    };
  },
  computed: {
    isOpenFolder:{
      get(){
        if (this.categorias.name == 'CATEGORIA') {
          return true
        }else{
          return this.isOpen
        }
      },
      set(val){
        this.isOpen = val
      }
      
    },
    // selectedCategory(){
    //     return this.$store.state.categorias.categoriasSeleccionadas;
    // },
    selectedCategory: {
      get: function () {
        return this.$store.state.categorias.categoriasSeleccionadas;
      },
      set: function (val) {
      },
    },
    isFolder: function () {
      return this.categorias.children && this.categorias.children.length;
    },
    isLeaf() {
      return this.categorias.children && !this.categorias.children.length;
    },
  },
  methods: {
    ...mapActions("categorias", ["agregarCategoriaSeleccionada"]),
    toggle: function (item) {
      if (item.children && item.children.length > 0) {
        this.isOpen = !this.isOpen;
      } else {
        this.$emit("make-folder", item);
        this.isOpen = true;
      }
    },
    doubleClick: function (item) {
      if (!item.children && !item.children.length) {
        // this.$emit("make-folder", item);
        // this.isOpen = true;
      }
    },
    changeCategory: function (categoria) {
      this.agregarCategoriaSeleccionada(categoria);
      // this.$emit('select-categoria', categoria)
    
    },
    

  },
};
</script>

<style  scoped>
ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
  content: attr(" ");
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;    
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}
li:nth-child(1) {
   list-style-type: none; 
}
</style>