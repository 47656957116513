var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{style:(_vm.item.color ? ("color: " + (_vm.item.color)) : '')},[_c('v-card',{attrs:{"flat":"","color":_vm.categoriaSelected
        ? _vm.categoriaSelected.name == _vm.item.name
          ? ''
          : ''
        : ''},on:{"click":function($event){return _vm.toggle(_vm.item)},"dblclick":function($event){return _vm.doubleClick(_vm.item)}}},[_c('v-row',[_c('v-container',[_c('span',{staticClass:"subtitle-2",style:(_vm.categoriaSelected
              ? _vm.categoriaSelected.name == _vm.item.name
                ? 'color: #B1D4E0'
                : ''
              : '')},[_vm._v(_vm._s(_vm.item.name))]),(_vm.isFolder)?_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(_vm._s(_vm.isOpen ? "mdi-menu-up" : "mdi-menu-down"))]):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-container',[(_vm.isFolder)?_c('ol',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"mt-2"},_vm._l((_vm.item.children),function(child,index){return _c('tree-item-categoria',{key:index,staticClass:"item",attrs:{"item":child},on:{"make-folder":function($event){return _vm.$emit('make-folder', $event)}}})}),1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }