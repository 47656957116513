<template>
  <span>
      <v-list-item class="">
      <v-list-item-content>
        <v-list-item-title
          ><span class="subtitle-2"
            ><b>Categorías</b></span
          ></v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <span class="text-body-2"><b></b></span>

    <template v-if="type == 'list'">
      <categorias-arbol-list-parents
        v-if="categoriasProductosFiltros && onlyParents"
        :categorias="categoriasProductosFiltros"
        :categoriasSeleccionadas="categoriasSeleccionadas"
        @select-categoria="setCategoria"
      ></categorias-arbol-list-parents>
      <categorias-arbol-list
        v-if="categoriasProductosFiltros && !onlyParents"
        :categorias="categoriasProductosFiltros"
        :categoriasSeleccionadas="categoriasSeleccionadas"
        @select-categoria="setCategoria"
      ></categorias-arbol-list>
    </template>
    <categorias-select-checkbox
      v-if="false && categoriasProductosFiltros"
      :categorias="categoriasProductosFiltros"
      @select-categoria="setCategoria"
    ></categorias-select-checkbox>
    <div class="tree" v-if="type == 'checkbox'">
      <categorias-arbol-list-tree
        v-if="categoriasArbol"
        v-model="selectedItems"
        :categorias="categoriasArbol"
        @select-categoria="setCategoria"
      ></categorias-arbol-list-tree>
      <span v-else class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </span>
    </div>
  </span>
</template>

<script>
import CategoriasArbolList from "@/components/categorias/visualizacion/categorias.arbol.list.vue";
import CategoriasArbolListParents from "@/components/categorias/visualizacion/categorias.arbol.list.parents.vue";

import CategoriasSelectCheckbox from "@/components/categorias/visualizacion/categorias.select.checkbox.vue";
import CategoriasArbolListTree from "@/components/categorias/visualizacion/categorias.arbol.list.tree.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "producto.filtro.categoria",
  components: {
    CategoriasArbolList,
    CategoriasSelectCheckbox,
    CategoriasArbolListTree,
    CategoriasArbolListParents
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "checkbox",
    },
    onlyParents: {
      type: Boolean,
      required: false,
      default: false
    },
    categoriasSeleccionadas: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data() {
    return {
      categorias: null,
      categoriasFilters: null,
      selectedItems: null,
    };
  },
  computed: {
    ...mapState("categorias", [
      "categoriasArbol",
      "categoriasProductosFiltros",
    ]),
  },
  methods: {
    ...mapActions("categorias", [
      "fetchCategoriasFilters",
      "fetchCategoriasProductosFiltros",
    ]),
    setCategoria(categoria) {
      this.$emit("select-categoria", categoria);

    },
   
  },
  created() {
    if (this.type == "checkbox") {
      this.fetchCategoriasFilters();
    } else {
      if (this.$route.query && this.$route.query.categoria) {
        var name = this.$route.query.categoria.split(/[-]+/).join(" ");
        this.fetchCategoriasProductosFiltros(name);
      } else {
        this.fetchCategoriasProductosFiltros();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>