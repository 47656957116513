<template>
  <div>
    <v-select
      v-if="categoria.length == 0"
      v-model="categoria"
      item-text="name"
      item-value="id"
      readonly
      multiple
      label="Categoría"
      @mousedown="dialogCategoria = true"
      :outlined="outlined"
    ></v-select>

    <v-select
      :outlined="outlined"
      v-else
      label="Categoría"
      multiple

      :items="categoriasProveedor"
      v-model="categoriasProveedor"
      item-text="name"
      item-value="id"
      hi
      clearable
      @click="changeCategoria()"
      @click:clear="clearData()"

      :eager="true"
    >
    </v-select>
    <v-dialog v-model="dialogCategoria" width="500">
      <v-card>
        <v-card-title>
          Seleccione una categoría
          <v-spacer></v-spacer>
          <v-icon large @click="dialogCategoria = false">mdi-close</v-icon>
        </v-card-title>
        <v-container>
          <keep-alive>
            <tree-categoria :categorias="categorias" @process-categoria="setCategoriaRegister"></tree-categoria>
          </keep-alive>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TreeCategoria from "@/views/categorias/consultar/TreeCategoria.vue";
import CategoriasAPI from "@/api/categorias";
export default {
  name: "categoria.select",
  components: {
    TreeCategoria,
  },
  props: {
    categoria: {
      type: Array,
      required: false,
      // default: () => ({ categoria: { id: null } }),
      default: null
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialogCategoria: false,
      categoriaSelect: [],
      categorias: null
    };
  },
  computed: {
    categoriasProveedor:{
      get(){

        return this.categoria
      },
      set(val){
        this.categoria.splice(0)
        return
        // Agrego este get y set porque al iniciar el evento clear, sale alerta de avoid prop mutation
      }
    }
   
  },

  methods: {
    changeCategoria() {
      // this.categoriaSelect = null;
      this.dialogCategoria = true;
    },
    clearData() {
      this.$emit("clearable")
      this.$emit("process-categoria", null);

    },
    setCategoriaRegister(tp) {
      const index = this.categoria.findIndex(cat => cat.id === tp.id);
      console.log(index);
      if (index > -1) {
        // This means id is present in the array, so remove it
        this.categoria.splice(index, 1);
      } else {
        // This means id is not present in the array, so add it
        this.categoria.push(tp);
      }
      this.dialogCategoria = false;
      this.$emit("process-categoria", tp);
    },
     fetchCategoria() {
      CategoriasAPI.index()
        .then((response) => {
          if (response.data.parent.children.length > 0) {
            this.categorias = response.data.parent
          } else {
            this.categorias = false;
          }
        });
    },
  },
  
 created() {
    this.fetchCategoria();
  },
  mounted() {
    if (this.categoria && this.categoria.id !== null && this.categoria.name !== "SIN CATEGORIA") {
      this.categoriaSelect = this.categoria;
    }
  },
};
</script>

<style lang="scss" scoped></style>
